/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/scss/bootstrap";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import 'styles/_forms.scss';
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

.subpage-container {
  width: 100%;
  //background-color: red;
}

.md-drppicker {
  .btn {
    background-color: $first-color !important;
  }

  td {
    &.active {
      background-color: $first-color !important;
    }
  }
}

.ngx-datatable {
  border-radius: $border-radius;

  //.ngx-datatable {
  //  border-radius: $border-radius-nested;
  //}
  &.material {
    box-shadow: none;

    .empty-row {
      text-align: center;
      padding: 0;
      margin-top: 25px;
    }
  }

  .datatable-header-cell {
    font-weight: bold !important;
    box-sizing: border-box;
    border-left: solid 1px transparent;

    &:hover {
      border-left-color: #eee;
    }
  }

  .custom-header-cell {
    display: flex;
    align-items: center;

    i {
      padding-left: 10px;
    }
  }

  .datatable-row-detail {
    background-color: #FFF !important;
    border-top: 1px solid $gray-3;

    .ngx-datatable {
      border-radius: 0;
      border-top: 1px solid $gray-3;
      border-bottom: 1px solid $gray-3;
    }
  }
}

.navbar-collapse {
  @media screen and (max-width: 991px) {
    background-color: white;
  }
}

.pointer {
  cursor: pointer;
}

.block-link {
  display: block;
}

//.custom-header-cell {
//
//
//  span {∂
//    &:not(.pointer) {
//      cursor: default;
//    }
//  }
//}

// ng-select styles

ng-select {
  &.ng-select-disabled {
    .ng-select-container {
      cursor: not-allowed;

      > * {
        pointer-events: none;
      }
    }
  }
}

.ng-select-multiple {
  padding-inline: 0 !important;
  border: none;

  .ng-select-container {
    min-height: 42px;

    .ng-value-container {
      padding-top: 5px !important;
      padding-left: 15px !important;

      .ng-input {
        padding: 0 !important;
        margin-top: -5px !important;

        input {
          height: fit-content;
        }
      }

      .ng-value {
        margin-top: 0 !important;
      }

      .ng-placeholder {
        top: 50% !important;
        transform: translateY(-50%);
        padding-bottom: 0 !important;
        padding-left: 0 !important;
      }

    }
  }
}

.ng-value-container {
  color: var(--fourth-font-color);
  font-size: 12px;
  padding-left: 0 !important;


  .ng-input {
    top: 10px !important;
    padding-left: 0 !important;

    input {
      margin-bottom: 0 !important;
      padding-left: 0 !important;
    }
  }
}

.ng-select {
  &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border-color: $third-color !important;
  }
}

ng-select {
  &.ng-select-disabled > .ng-select-container {
    background-color: #eaeaea !important;
  }
}

.ng-select.ng-select-single {
  .ng-select-container {
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    height: 42px;
    padding-left: 15px;

    input {
      height: initial;
    }


    .ng-input {
      left: 15px !important;
      //top: 5px !important;
    }
  }

  .ng-option {
    font-size: 12px;
  }
}

// ng-select borders

.ng-select {
  .ng-select-container {
    border: 1px solid #000 !important;
    border-radius: $border-radius;
  }

  &.ng-select-opened.ng-select-multiple .ng-dropdown-panel {
    margin-top: -3px;
  }

  &.ng-select-opened {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .ng-select-container {
      border-color: #000;
    }

    .ng-dropdown-panel {
      overflow: hidden;
      border: 1px solid #000;
      border-top-color: #e6e6e6;
      border-radius: 0 0 $border-radius $border-radius;

      .ng-option:last-child {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
    }
  }
}


.append-element-right {
  border-right: none !important;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.exclude .ng-select-container {
  border-right: none !important;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.toggle-sale-users {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  padding: 0 10px;
  border-bottom-right-radius: $border-radius;
  border-top-right-radius: $border-radius;
  background-color: #FFF;

  i {
    min-width: 20px;
  }
}

// Fullcallendar

full-calendar.month-view {
  a {
    &.fc-col-header-cell-cushion {
      color: $first-color !important;
    }

    &.fc-daygrid-day-number {
      color: $first-color !important;
    }

    &.fc-daygrid-event {
      color: $first-color !important;
    }
  }

  .field-container {
    margin-bottom: 10px;
  }

  .event-tile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    //border-radius: 5px;
    //border: 2px solid $green-color;
    //background: $first-color;
    color: $green-color;
    padding: 5px 0;
  }

  .fc-v-event {
    background: white !important;
    border: none !important;
  }

  .fc-h-event {
    background: white !important;
    border: 2px solid $green-color !important;;
    color: $green-color !important;;
  }

  .fc-day-today {
    background-color: rgba(20, 98, 241, 0.2) !important;
  }
}

full-calendar.week-view {
  a {
    &.fc-col-header-cell-cushion {
      color: $first-color !important;
    }

    &.fc-daygrid-day-number {
      color: $first-color !important;
    }

    &.fc-daygrid-event {
      color: $first-color !important;
    }
  }

  .fc-v-event {
    //background: $green-color !important;
    //border: 2px solid $green-color !important;
  }

  .fc-event-main {
    color: white !important;
    text-align: center;
  }

  .fc-agenda-slots td div {
    height: 40px !important;
  }

  .event-tile {
    .property-icons-container {
      display: flex;
    }

    .event-cog {
      position: absolute;
      top: 1px;
      right: 5px;
    }
  }

  .fc-col-header-cell-cushion {
    width: 100%;
  }

  .header-content {
    display: flex;
    justify-content: space-between;
  }

  //.fc-timegrid-slots tr {
  //  height: 35px !important;
  //}

  .fc-timegrid-slot {
    line-height: 30px;
  }

  .highlight-day {
    background: rgba(255, 0, 0, 0.15);
  }

  .fc-day-today {
    background-color: rgba(20, 98, 241, 0.2) !important;
  }
}

full-calendar.week-view-simple {
  a {
    &.fc-col-header-cell-cushion {
      color: $first-color !important;
    }

    &.fc-daygrid-day-number {
      color: $first-color !important;
    }

    &.fc-daygrid-event {
      color: $first-color !important;
    }
  }

  .fc-v-event {
    background: $first-color !important;
    border: 2px solid $first-color !important;
  }

  .fc-event-main {
    color: white !important;
    text-align: center;
    overflow: hidden;
  }

  .fc-agenda-slots td div {
    height: 40px !important;
  }

  .event-tile {
    .event-icon {
      position: absolute;
      top: 1px;
      right: 5px;
      display: flex;
    }

    .sale-user-icon {
      position: absolute;
      top: 5px;
      left: 5px;
      display: flex;
    }

    .slot-schema-icons-container {
      display: flex !important;
      justify-content: space-between !important;
    }

    .slot-icons {
      display: flex !important;
    }
  }

  .fc-col-header-cell-cushion {
    width: 100%;
  }

  .header-content {
    display: flex;
    justify-content: space-between;
  }

  //.fc-timegrid-slots tr {
  //  height: 35px !important;
  //}

  .fc-timegrid-slot {
    line-height: 30px;
  }

  .highlight-day {
    background: rgba(255, 0, 0, 0.15);
  }

  .fc-day-today {
    background-color: transparent !important;
  }
}

//.fc .fc-timegrid-slot {
//  min-height: 1.5em;
//  height: initial !important;
//  border-bottom: 0 !important;
//}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';


.timepicker-backdrop-overlay {
  z-index: 999999 !important;

}

.timepicker-overlay {
  z-index: 999999 !important;
}

.timepicker__header {
  background-color: $first-color !important;
}

.timepicker-button {
  color: $first-color !important;
}

.clock-face__number > span.active {
  background-color: $first-color !important;
}

.clock-face__clock-hand {
  background-color: $first-color !important;
}

.clock-face__clock-hand_minute {
  &:before {
    border: 4px solid $first-color !important;
  }
}

.md-drppicker .ranges {
  ul {
    li {
      button.active {
        background-color: #001f36 !important;
      }
    }
  }
}

.accordion {
  .btn-link {
    color: #001f36 !important;
  }

  button {
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }
}

ngx-tooltip-content {
  white-space: nowrap;
}

//.md-drppicker {
//  min-width: 591px !important;
//}

@media screen and (max-width: 1225px) {
  .md-drppicker {
    min-width: auto !important;
    max-width: 250px !important;
  }
}

app-add-or-edit-slot-modal {
  font-size: 13px;
}

.table-with-details datatable-scroller {
  width: inherit !important;
}

.datatable-header-inner, datatable-selection {
  width: inherit !important;
}

.datatable-scroll {
  width: inherit !important;
  max-width: 100% !important;
}

.datatable-header .datatable-row-center, .datatable-row-wrapper datatable-body-row {
  max-width: inherit !important;
  width: inherit !important;
}

.datatable-row-wrapper .datatable-row-center {
  max-width: inherit !important;
}

.datatable-row-wrapper {
  display: inline-block !important;
}

.datatable-body-cell {
  word-break: break-word;
}

.datatable-row-wrapper, .datatable-summary-row {
  display: inline-block !important;
  min-width: 100% !important;
}

.datatable-row-center {
  width: 100% !important;
}

.table-fixed-rows {
  .datatable-row-center {
    min-width: 100% !important;
  }
}

.datatable-body-row {
  width: inherit !important;
  max-width: inherit !important;
}

// To set column width lower than 150px or 120px use directive "appCustomColumnWidth"
.datatable-header-cell, .datatable-body-cell {
  &:not(.custom-column-width) {
    min-width: 150px;
  }

  width: 100% !important;
}


.sm-col-width {
  .datatable-header-cell, .datatable-body-cell {
    &:not(.custom-column-width) {
      min-width: 120px;
    }
  }
}


.settings-dropdown {
  position: relative;

  .dropdown-container {
    display: none;
    position: fixed;
    width: fit-content;
    z-index: 9999;
    background-color: #FFF;
    padding-left: 0;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 5px;

    .dropdown-item {
      cursor: pointer;
      user-select: none;
      font-size: .9rem;
    }
  }

}

.fc-col-header th:last-child .dropdown-container {
  transform: translateX(-85%);
}

.show-dropdown {
  display: block !important;
}


// Dashboard tiles
.stat-tiles {
  padding: 10px;

  &:last-child {
    padding-right: 0;
  }

  .tile-inner {
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 150px;
    text-align: center;

    .value {
      color: $second-color;
      font-size: 35px;
      line-height: 35px;
    }

    .label {
      font-weight: bold;
      font-size: 15px;
      line-height: 15px;
    }

    &.dark {
      background-color: $first-color;

      .label {
        color: white;
      }
    }
  }
}

// Span Statuses
span.status-span {
  &.not-started, &.wait-for-commitment, &.failed, &.finalized, &.partly-paid, &.timeout, &.processing, &.processing-error,
  &.pending-payment, &.paid, &.failed, &.unknown, &.preauthed, &.preauth-failed, &.preauth-unknown,
  &.committed, &.commit-failed, &.commit-unknown, &.charged, &.charge-failed, &.charge-unknown,
  &.returned, &.pending-return, &.failure, &.success, &.voided, &.void-failed, &.void-unknown,
  &.refunded, &.refund-failed, &.refund-unknown, &.pending-load, &.loaded, &.not-submitted, &.settled,
  &.expired, &.other, &.error, &.pending, &.offline, &.online, &.sent, &.queued, &.requested,
  &.waiting-for-verification, &.waiting-for-confirmation, &.rejected, &.expired, &.confirmed,
  &.waiting, &.approved, &.waiting-for-payment, &.requeued, &.send, &.reject, &.bounce, &.complaint, &.delivery, &.open, &.click, &.initial,
  &.finished-real, &.finished-virtual, &.finished-item-returns, &.finished {
    border-radius: 5px;
    display: inline-block;
    padding: 2px 10px;
    font-weight: 600;
    color: white;
  }

  &.failed, &.processing-error, &.timeout, &.failed, &.unknown, &.preauth-failed, &.failure,
  &.preauth-unknown, &.commit-failed, &.commit-unknown, &.charge-failed, &.charge-unknown,
  &.void-failed, &.void-unknown, &.refund-failed, &.refund-unknown, &.expired, &.error, &.offline,
  &.rejected, &.reject, &.complaint {
    background-color: #FF0F19;
  }

  &.not-started, &.other, &.not-submitted {
    background-color: gray;
  }

  &.wait-for-commitment, &.partly-paid, &.preauthed, &.queued, &.waiting-for-confirmation, &.waiting-for-payment, &.pending,
  &.pending-payment, &.committed, &.pending-return, &.pending-load, &.waiting-for-verification, &.waiting, &.requested, &.requeued, &.send, &.processing {
    background-color: #FF9103;
  }

  &.voided, &.refunded, &.returned, &.bounce {
    background-color: #5E52DE;
  }

  &.finalized, &.paid, &.charged, &.success, &.loaded,
  &.online, &.settled, &.sent, &.confirmed, &.approved, &.delivery, &.open, &.click, &.finished-real, &.finished-virtual, &.finished-item-returns, &.finished {
    background-color: #00CC47;
  }

  &.initial {
    background-color: #ffd500;
  }
}

.page-header-container {
  margin-top: 15px;
  margin-bottom: 5px;
}

img.action-icon {
  display: inline-block;
  cursor: pointer;
  width: 26px;
  aspect-ratio: 1 / 1;

  &:not(:last-child) {
    margin-right: 5px;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: .5;
  }
}

.inventory-table .datatable-body-cell {
  overflow-y: hidden;
}

.modal-xl {
  width: 90%;

  @media screen and(min-width: 1200px) {
    max-width: 1400px !important;
  }

  @media screen and(min-width: 992px) {
    max-width: none;
  }

  @media screen and(min-width: 576px) {
    max-width: none;
  }
}

.email-template-editor {
  ngx-monaco-editor {
    min-height: 580px;

    .editor-container {
      height: 100%;
    }
  }
}

.week-picker-calendar {
  ngx-daterangepicker-material {
    .md-drppicker {
      min-width: 225px !important;

      .table-condensed tbody {
        tr:hover {
          background-color: #eee !important;
          transition: 450ms cubic-bezier(.23, 1, .32, 1);
          opacity: .8;
          border-radius: 2em;

          & > td {
            &.active:hover {
              background-color: #161313 !important;
              color: white !important;
              border-radius: 0;

              &.end-date {
                border-radius: 0 2em 2em 0;
              }

              &.start-date {
                border-radius: 2em 0 0 2em;
              }
            }

            &:not(.active):hover, &.off {
              background-color: #dde2e4 !important;
              border-radius: 0 !important;
              color: inherit !important;
            }
          }
        }
      }
    }

    .calendar.right {
      display: none;
    }
  }
}

.md-drppicker .clear svg {
  display: none;
}

.md-drppicker .buttons_input > button {
  text-transform: capitalize;
}
