@import "variables";

input {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: none;
    border-bottom: 2px solid white;
    //-webkit-text-fill-color: $gray-4;
    -webkit-box-shadow: 0 0 0px 1000px #f0f0f0 inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  &::placeholder {
    //color: $text-color-3;
    //font-family: Montserrat;
    font-weight: light;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  &[type='email'],
  &[type='text'],
  &[type='url'],
  &[type='password'],
  &[type='number'] {
    background-color: white;
    border: 1px solid $third-color;
    outline: none;
    width: 100%;
    //color: $gray-4;
    //margin-bottom: 10px;
    font-size: 12px;
    height: 42px;
    border-radius: $border-radius;
    padding-left: 15px;
    padding-right: 15px;


    // ng-select fix
    &[disabled]:not([role='combobox']) {
      background-color: #eaeaea !important;
      //background-color: $gray-1;
    }
  }

  &[type='checkbox'],
  &[type='radio'] {
    margin-right: 5px;
  }

  &[type=radio]:checked ~ .check::before {
    background: #0DFF92 !important;
  }

  &[type='text'] {
    &.custom-search {
      margin-right: 25px;
    }
  }
}

.ng-select-multiple {
  background-color: white;
  border: 1px solid $third-color;
  outline: none;
  width: 100%;
  //color: $gray-4;
  //margin-bottom: 10px;
  font-size: 12px;
  min-height: 42px;
  border-radius: $border-radius;
  padding-left: 5px;
  padding-right: 5px;

  .ng-select-container {
    border: none;
    outline: none;

    input[role='combobox'] {
      height: 30px;
    }
  }

  .ng-value {
    background-color: $third-color !important;
    color: white;
  }

}

.hide-dropdown {
  &.ng-select-multiple {
    ng-dropdown-panel {
      display: none;
    }
  }
}


button,
input {
  outline: none;
}

input[type="button"], button.custom-button {
  background-color: $second-color;
  color: white;
  border: none;
  text-transform: uppercase;
  font-size: 12px;
  min-width: 180px;
  height: 50px;
  border-radius: 50px;
  //box-shadow: 0 1px 1px 0 #2fa8c5;
  letter-spacing: 3px;
  padding: 0 20px;

  &[disabled] {
    //background-color: $gray-2;
    cursor: not-allowed;
  }

  &.small {
    padding: 0 10px;
    height: 30px;
    min-width: 100px;
    text-decoration: initial;
    letter-spacing: initial;
  }
}

input[type="button"], button.general-button, button.general-button-secondary {
  background-color: $second-color;
  color: white;
  border: none;
  font-weight: bold;
  text-transform: none;
  font-size: 12px;
  border-radius: 50px;
  padding: 0 10px;
  height: 30px;
  min-width: 100px;
  text-decoration: initial;
  letter-spacing: initial;

  &.danger {
    background-color: $red-color;
  }

  &[disabled] {
    background-color: $gray-2;
    cursor: not-allowed;
  }
}

input[type="button"], button.rectangle-button, button.rectangle-button-secondary {
  background-color: $second-color;
  color: white;
  border: none;
  text-transform: none;
  font-weight: bold;
  font-size: 12px;
  border-radius: $border-radius;
  padding: 0 10px;
  height: 40px;
  min-width: 100px;
  text-decoration: initial;
  letter-spacing: initial;

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

button.general-button-secondary, button.rectangle-button-secondary {
  background-color: transparent;
  color: $second-color;
  border: 1px solid $second-color;

  &[disabled] {
    background-color: transparent;
    border-color: $gray-2;
    color: $gray-2;
  }
}

textarea {
  width: 100%;
  border-radius: $border-radius;
  border: 1px solid $third-color;
  resize: vertical;
  font-size: 12px !important;
  padding: 10px 15px;
  min-height: 100px;

  &[disabled] {
    background-color: #eaeaea;
  }
}

label.custom-label {
  text-transform: uppercase;
  font-size: 11px;
  //color: $gray-4;
  margin-bottom: 5px;
  font-weight: bold;

  &.no-margin {
    margin: 0
  }
}

.form-error {
  color: $danger-color;
  font-size: 10px;
  position: absolute;
  left: 15px;
  top: 65px;
}

a {
  color: $second-color !important;

  &.disabled {
    color: lightgray !important;
    cursor: not-allowed !important;
  }
}


.search-field {
  position: relative;

  .remove-icon {
    position: absolute;
    top: 0;
    /* height: 100%; */
    right: 15px;
    display: flex;
    align-items: center;
    bottom: 10px;
    font-size: 12px;
  }
}

val-errors {
  color: #e86060;
  font-size: 12px;
  margin-top: -8px;
}

.checkbox-container {
  width: 100%;
  display: flex;
  align-items: center;
  height: 40px;
}


.filter-container {
  .ng-select-container {
    min-width: 200px;
  }

  .search-container {
    position: relative;

    input {
      padding-right: 30px !important;
    }

    .close-icon {
      position: absolute;
      top: 1px;
      right: 1px;
      bottom: 1px;
      border-radius: 3px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: $first-color;
      background: white;
    }
  }
}

.calendar-filter {
  .ngx-daterangepicker-action {
    background-color: $first-color;
    border-radius: $border-radius 0 0 $border-radius;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;

    i {
      pointer-events: none;
    }
  }

  input {
    border-radius: 0 $border-radius $border-radius 0;
    min-width: 185px;
  }
}

.button-container {
  height: 40px;
  display: flex;
  align-items: center;
}


.ng-select.disable-arrow .ng-arrow-wrapper .ng-arrow {
  display: none;
}

.ng-select.disable-dropdown ng-dropdown-panel {
  display: none;
}

.ng-select.disable-clear-all .ng-clear-wrapper {
  display: none;
}

h2, h3, h4, h5 {
  font-weight: 600 !important;
}

