$first-color: #161313;
$second-color: #00CCFF;
$third-color: #2F2F2F;

$danger-color: #ff8282;
$red-color: #dd2020;
$green-color: #27924F;

$gray-1: #F7F7F7;
$gray-2: #c2c2c2;
$gray-3: #0000001f;

$border-radius: 6px;
$border-radius-nested: 6px;

.primary-font-color {
  color: $first-color;
}


//$first-color: #001F36;
//$second-color: #F2BE00;
//$third-color: #032546;
//
//$danger-color: #ff8282;
//$red-color: #dd2020;
//$green-color: #27924F;
//
//$gray-1: #F7F7F7;
//
//.primary-font-color {
//  color: $first-color;
//}
